import React, { useState } from 'react'
import { navigate } from 'gatsby'

// Components
import { Seo, Basic as AltButton, Loading, PageHeader } from '../components'

// Styles
import { PaymentInfoContainer, StyledForm } from '../styles/LoginPage.styles'
import CreditCard from '../components/CreditCard'
import { useStore } from '../context/StoreContext'
import { usePrismic } from '../context/PrismicContext'

const PaymentInfo = () => {
  const { cardsOnFile, getSelectedCard, updateSelectedCard, isLoadingStore } =
    useStore()
  const [selectedCardId, setSelectedCardId] = useState(
    getSelectedCard().creditCardGuid
  )
  const {
    prismicData: { prismicPaymentInfoPage },
  } = usePrismic()

  const {
    update_payment_info,
    payment_information,
    loading_cards,
    add_new_credit_debit_card,
  } = prismicPaymentInfoPage

  const onSubmit = event => {
    event.preventDefault()
    const selectedCard = cardsOnFile.find(({ creditCardGuid }) => {
      return creditCardGuid === selectedCardId
    })
    // TODO: need to add in how we store the selected card
    updateSelectedCard(selectedCard)
    navigate('/cart')
  }
  if (isLoadingStore)
    return <Loading loading={isLoadingStore} message={loading_cards[0].text} />
  return (
    <>
      <Seo title={update_payment_info[0].text} />
      <PageHeader>{payment_information[0].text}</PageHeader>
      <PaymentInfoContainer>
        <StyledForm onSubmit={onSubmit}>
          {cardsOnFile.length > 0 &&
            cardsOnFile.map(creditCard => (
              <CreditCard
                creditCard={creditCard}
                selectedCardId={selectedCardId}
                setSelectedCardId={setSelectedCardId}
                key={creditCard.creditCardGuid}
              />
            ))}
        </StyledForm>
        <AltButton onClick={() => navigate('/add-card')}>
          {add_new_credit_debit_card[0].text}
        </AltButton>
      </PaymentInfoContainer>
    </>
  )
}

export default PaymentInfo
