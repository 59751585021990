import React from 'react'
// Components
import { Primary as PrimaryButton } from '..'

// Styles
import { Container } from '../../styles/LoginPage.styles'
import {
  CardTitle,
  DataError,
  UnderlinedContaner,
} from './CreditCard.styles'

import { CreditCard } from '../../types/PaymentsTypes'
import { DateUtils } from '../../utils/dateUtils'

import { usePrismic } from '../../context/PrismicContext'

type CreditCardProps = {
  creditCard: CreditCard,
  selectedCardId: string,
  setSelectedCardId: (s: string) => void
}

const CreditCardComponent = ({ creditCard, selectedCardId, setSelectedCardId }: CreditCardProps) => {
  const {
    prismicData: { prismicPaymentInfoPage },
  } = usePrismic()

  const {
    card_missing_error,
    expires,
    use_this_payment,
  } = prismicPaymentInfoPage

  const {
    lastFourDigits,
    creditCardGuid,
    creditCardType,
    expirationDate,
  } = creditCard

  if (
    !lastFourDigits ||
    !creditCardGuid ||
    !creditCardType ||
    !expirationDate
  ) {
    return (
      <UnderlinedContaner>
        <DataError>{card_missing_error[0].text}</DataError>
      </UnderlinedContaner>
    )
  }

  const handleChanged: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const selectedCardId = target.value
    setSelectedCardId(selectedCardId)
  }

  const isSelected = selectedCardId === creditCardGuid
  return (
    <UnderlinedContaner>
      <Container key={lastFourDigits}>
        <input
          id={creditCardGuid}
          name="creditCard"
          type="radio"
          value={creditCardGuid}
          onChange={handleChanged}
          checked={isSelected}
          data-qa={`radioButton-${lastFourDigits}`}
        />
        <Container direction="column" align="start" padding="0 40px">
          <CardTitle data-qa={`cardTitle-${lastFourDigits}`}>{`${creditCardType} **** ${lastFourDigits}`}</CardTitle>
          <span>{`${expires[0].text} ${DateUtils.getMMYYYYFormat(expirationDate)}`}</span>
        </Container>
      </Container>
      <Container justify="start" padding="0 .5em">
        <PrimaryButton
          style={{
            width: '60%',
            margin: '0',
            display: isSelected ? 'flex' : 'none',
          }}
          type="submit"
          data-qa={`usePaymentButton-${lastFourDigits}`}
        >
          {use_this_payment[0].text}
        </PrimaryButton>
      </Container>
    </UnderlinedContaner>
  )
}

export default CreditCardComponent
