import styled from 'styled-components'

export const CardTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
`

export const DataError = styled.span`
  padding: 40px;
`

interface UnderlinedContanerProps {
  justify: string,
  align: string
}

export const UnderlinedContaner = styled.label<Partial<UnderlinedContanerProps>>`
  border-bottom: 1px solid #979797;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: 100%;
  padding: 1em 0;
  cursor: pointer;
`